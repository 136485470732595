@import "../../styles/colors";

.app-about {
  background-color: $secondary-bg;

  .bg-container {
    height: 500px;
    background-image: url("../../assets/images/about-us.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;

    @media only screen and (max-width: 767px) {
      height: 300px;
    }
  }

  .section-container {
    margin: 50px 6%;
    padding: 50px 6%;
    background-color: $bg-white;

    @media only screen and (max-width: 767px) {
      margin: 20px 0 0;
      padding: 0;
    }

    .section-image {
      width: 500px;
      height: 300px;

      @media only screen and (max-width: 767px) {
        width: 100vw;
        height: 300px;
      }
    }

    .section-content {
      margin-left: 40px;

      @media only screen and (max-width: 767px) {
        padding: 20px 10px;
        margin-left: 0;
      }
    }
  }

  .section-breaker {
    padding: 30px 30%;

    @media only screen and (max-width: 767px) {
      padding: 15px 10%;
    }

    .quote {
      font-size: 28px;

      @media only screen and (max-width: 767px) {
        font-size: 24px;
      }
    }
  }
}
