@import "../../styles/colors";
@import "../../styles/variables";

.app-layout {
  .layout-header {
    height: $header-height;
    background-color: #fff;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 10;

    @media only screen and (max-width: 767px) {
      border-bottom: 0.5px solid $border-default;
      padding-inline: 12px;
    }
  }

  .layout-content {
    background-color: $bg-white;
  }

  .layout-footer {
    background-color: $bg-dark;
    padding: 3rem 0;

    @media only screen and (max-width: 767px) {
      padding: 1rem 0;
    }
  }
}
