@import "./flex";
@import "./fonts";
@import "./colors";
@import "./typography";
@import "./spacing";
@import "./antd-overrides";

html,
body,
#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  font-family: SplineSans;
  color: $primary-font-color;
  background-color: $bg-white;
}

#root {
  overflow-y: auto;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cursor-pointer {
  cursor: pointer;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.stretch {
  height: 100%;
  width: 100%;
}

.vertical-scroll {
  overflow-y: auto;
}

.relative {
  position: relative;
}

.page-container {
  padding: 64px 0 0px;
}

.page-layout {
  padding-inline: 50px;

  @media only screen and (max-width: 767px) {
    padding-inline: 12px;
  }
}
