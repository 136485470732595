@import "../../styles/colors";

.app-footer {
  padding: 0 3rem;

  @media only screen and (max-width: 767px) {
    padding: 0 1rem 60px;
    align-items: center;
  }

  .footer-section {
    @media only screen and (max-width: 767px) {
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }
  }

  .copyright-text {
    margin-top: 3rem;
  }
}
