@import "../../styles/colors";

.app-menu {
  margin-left: 5rem;

  .ant-menu-item-icon {
    display: none !important;
  }

  .ant-menu-item {
    color: #6c7577;

    .ant-menu-title-content {
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;

      &:hover {
        color: #2e6b75;
      }
    }

    &.ant-menu-item-selected {
      color: #2e6b75;
    }
  }

  @media only screen and (max-width: 767px) {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    width: 100%;
    height: 80px;
    justify-content: center;
    border-top: 0.5px solid $border-default;
    margin-left: 0;

    .ant-menu-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-inline: 20px;

      .ant-menu-title-content {
        font-size: 16px;
        margin-inline-start: 0 !important;
      }

      .ant-menu-item-icon {
        display: block !important;
        font-size: 20px;
      }
    }
  }
}
