@import "./colors";

// Card
.ant-card {
  .ant-card-body {
    width: 100%;
    height: 100%;
  }
}

// Button
.ant-btn {
  &.ant-btn-primary {
    background-color: $bg-dark;

    &:not(:disabled):active,
    &:not(:disabled):hover {
      background-color: $bg-dark;
    }
  }

  &:not(:disabled):focus-visible {
    outline: none;
  }
}

* {
  animation-duration: 0s !important;
}
