.p {
  &--10 {
    padding: 10px;
  }
  &--t-10 {
    padding-top: 10px;
  }
  &--r-10 {
    padding-right: 10px;
  }
  &--b-10 {
    padding-bottom: 10px;
  }
  &--l-10 {
    padding-left: 10px;
  }

  &--20 {
    padding: 20px;
  }
  &--t-20 {
    padding-top: 20px;
  }
  &--r-20 {
    padding-right: 20px;
  }
  &--b-20 {
    padding-bottom: 20px;
  }
  &--l-20 {
    padding-left: 20px;
  }
}

.m {
  &--10 {
    margin: 10px;
  }
  &--t-10 {
    margin-top: 10px;
  }
  &--r-10 {
    margin-right: 10px;
  }
  &--b-10 {
    margin-bottom: 10px;
  }
  &--l-10 {
    margin-left: 10px;
  }

  &--20 {
    margin: 20px;
  }
  &--t-20 {
    margin-top: 20px;
  }
  &--r-20 {
    margin-right: 20px;
  }
  &--b-20 {
    margin-bottom: 20px;
  }
  &--l-20 {
    margin-left: 20px;
  }
}
