@import "../../styles/colors";

.app-header {
  .app-name {
    color: $primary-font-color;

    @media only screen and (max-width: 767px) {
      font-size: 20px;
    }
  }

  .user-name {
    width: 180px;
  }

  .ant-menu {
    &.ant-menu-horizontal {
      border-bottom: none;
    }

    .ant-menu-item-selected {
      color: $primary-font-color;

      &:after {
        border-bottom-color: $primary-font-color;
      }
    }

    .ant-menu-item:hover::after {
      border-bottom-color: $primary-font-color;
    }
  }
}
