// Background Colors
$primary-bg: #49858f;
$secondary-bg: #eff6fa;
$tertiary-bg: #fff7ec;
$bg-white: #ffffff;
$bg-dark: #2e6b75;

// Font Colors
$primary-font-color: #2e6b75;
$secondary-font-color: #6c7577;
$ternary-font-color: #b9cdd0;
$font-white: #ffffff;
$font-black: #000000;
$font-color-dark: #767676;

// Border Colors
$border-default: #c1dce0;
