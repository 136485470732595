@import "../../styles/colors";

.app-contact {
  .p-section {
    height: 680px;

    &.primary {
      background-color: $secondary-bg;
    }
    &.secondary {
      background-color: $tertiary-bg;
    }
  }

  .page-content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    height: 80%;
    width: 80%;
    background-color: transparent;

    @media only screen and (max-width: 767px) {
      position: relative;
      height: 100%;
      width: 100%;
    }

    .content-overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 10%;
      background-color: white;
      z-index: 1;
    }

    .content-container {
      padding: 4% 0;
      z-index: 2;

      @media only screen and (max-width: 767px) {
        padding: 0;
      }

      .content {
        &.primary {
          flex: 2;
          background-color: $bg-dark;
          padding: 20px;
        }

        &.secondary {
          flex: 4;
          padding: 20px 40px;

          .contact-form {
            label {
              color: $primary-font-color;
            }
          }
        }

        .content-item {
          margin-bottom: 10px;

          @media only screen and (max-width: 767px) {
            text-align: start;
          }
        }

        .social-media-panel {
          color: $font-white;
          font-size: 24px;
          margin-top: 20px;

          .anticon {
            &:not(:first-child) {
              margin-left: 20px;
            }
          }
        }
      }
    }
  }
}
