@import "../../styles/colors";

.app-home {
  .text-wrapper {
    padding: 10rem 8rem 0 0rem;

    @media only screen and (max-width: 767px) {
      padding: 6rem 0rem 0 0rem;
    }
  }
  .title-img {
    position: relative;
    z-index: 10000;
    object-fit: cover;
    border-end-start-radius: 5rem;

    @media only screen and (max-width: 767px) {
      z-index: 0;
      border-end-start-radius: 0;
    }
  }

  .feature-box {
    background: $primary-bg;
    border-radius: 45px;
    padding: 4rem;
    flex-flow: column nowrap;
    margin-left: 0.4rem;
    margin-right: 0.4rem;

    @media only screen and (max-width: 767px) {
      margin: 0;
      padding: 2.5rem;

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }

  .secondary-section {
    padding: 5rem 0;

    @media only screen and (max-width: 767px) {
      padding: 3rem 0;
    }

    &.heighlight {
      background-color: $secondary-bg;
    }

    .section-container {
      padding: 0 5rem;

      @media only screen and (max-width: 767px) {
        padding: 0;
      }

      .section-header {
        border-bottom: 1px solid $border-default;
      }

      .section-body {
        @media only screen and (max-width: 767px) {
          padding: 0 12px;
          margin-top: 20px;
        }

        .section-box {
          padding: 1.5rem 0;

          &:not(:first-child) {
            padding: 1.5rem;

            .data-card {
              padding: 0 55px;
              border-left: 1px solid $border-default;

              @media only screen and (max-width: 767px) {
                border-left: none;
                padding: 0;
              }
            }

            @media only screen and (max-width: 767px) {
              padding: 0;
            }
          }

          @media only screen and (max-width: 767px) {
            padding: 0;
          }
        }
      }
    }
  }
}
