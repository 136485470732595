// Flex grid limit. Flex scoped variable
$gridLimit: 100;

// Flex scoped mixin
@mixin flex-grid-list {
  @for $i from 0 through $gridLimit {
    &--#{$i} {
      flex-basis: #{$i * 1%};
    }
  }
}

.flex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  &--responsive {
    display: flex;

    @media only screen and (max-width: 767px) {
      display: none;
    }
  }

  &--fit {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  &--center {
    align-items: center;
    justify-content: center;
  }

  &--self-center {
    align-self: center;
  }

  &--align-stretch {
    align-items: stretch;
  }

  &--align-start {
    align-items: flex-start;
  }

  &--align-end {
    align-items: flex-end;
  }

  &--align-center {
    align-items: center;
  }

  &--justify-start {
    justify-content: flex-start;
  }

  &--justify-end {
    justify-content: flex-end;
  }

  &--justify-center {
    justify-content: center;
  }

  &--space-between {
    justify-content: space-between;
  }

  &--space-around {
    justify-content: space-around;
  }

  &--space-evenly {
    justify-content: space-evenly;
  }

  &--column {
    flex-direction: column;
  }

  &--column-reverse {
    flex-direction: column-reverse;
  }

  &--row {
    flex-direction: row;
  }

  &--row-responsive {
    flex-direction: row;

    @media only screen and (max-width: 767px) {
      flex-direction: column;
    }
  }

  &--row-reverse {
    flex-direction: row-reverse;
  }

  &--wrap {
    flex-wrap: wrap;
  }

  &--nowrap {
    flex-wrap: nowrap;
  }

  /**
   * Flex grid styles
   * Use flex grid in percentage 
   */
  @include flex-grid-list;
}
