@import "./colors";

.font {
  // Font Family
  &--primary-family {
    font-family: SplineSans;
  }
  &--secondary-family {
    font-family: Outfit;
  }

  // Color
  &--primary-color {
    color: $primary-font-color;
  }
  &--secondary-color {
    color: $secondary-font-color;
  }

  // Font Weight
  &--default-bold {
    font-weight: 400;
  }
  &--semi-bold {
    font-weight: 500;
  }
  &--bold {
    font-weight: 600;
  }
  &--extra-bold {
    font-weight: 700;
  }

  // Font Size
  &--thin {
    font-size: 10px;
  }
  &--small {
    font-size: 12px;
  }
  &--default {
    font-size: 14px;
  }
  &--medium {
    font-size: 1.5rem;
  }
  &--large {
    font-size: 18px;
  }
  &--x-large {
    font-size: 20px;
  }

  &--xx-large {
    font-size: 24px;
  }

  &--xxx-large {
    font-size: 4.75rem;
  }

  // Predefined Class
  &--main-heading {
    font-family: SplineSans;
    font-size: 4.75rem;
    font-weight: 400;
    color: $primary-font-color;
    line-height: 1.1;

    @media only screen and (max-width: 767px) {
      font-size: 2.2rem;
      line-height: 1.2;
      text-align: center;
    }
  }

  &--section-heading {
    font-family: SplineSans;
    font-size: 2rem;
    line-height: 1.5;
    font-weight: 400;

    &.invert {
      color: $font-white;
    }

    @media only screen and (max-width: 767px) {
      font-size: 1.5rem;
      line-height: 1.5;
      text-align: center;
    }
  }

  &--section-sub-heading {
    font-family: Outfit;
    font-size: 1.2rem;
    line-height: 1.5;
    font-weight: 400;
    color: $font-white;

    &.invert {
      color: $primary-font-color;
    }

    @media only screen and (max-width: 767px) {
      font-size: 1.1rem;
      line-height: 1.5;
      text-align: center;
    }
  }

  &--section-content {
    font-family: Outfit;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.5;
    color: $secondary-font-color;
    word-break: break-word;
    word-wrap: break-word;

    &.invert {
      color: $ternary-font-color;
    }

    &.dark {
      color: $font-color-dark;
    }

    @media only screen and (max-width: 767px) {
      font-size: 1.1rem;
      line-height: 1.5;
      text-align: center;
    }
  }

  &--sub-section-content {
    font-family: Outfit;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.7;
    color: #b9cdd0;

    &.invert {
      color: $font-white;
    }

    @media only screen and (max-width: 767px) {
      font-size: 0.9rem;
      line-height: 1.5;
      text-align: center;
    }
  }
}
